import React from "react";
import { Button } from "@wbly/ui";
import type { BrandHeroSlice } from "@wbly/data-storefront";
import useBrandHero from "./useBrandHero";
import styles from "./BrandHero.module.css";

type BrandHeroProps = BrandHeroSlice["body"];

const { container, imageContainer, contentWrapper, content, cta } = styles;

const BrandHeroComponent: React.FC<BrandHeroProps> = ({
  title,
  description,
  ctaLabel,
  url,
  image,
  theme,
  cutout,
  invert,
  id,
}) => {
  // Use the custom hook to manage cutout behaviour
  const { handleClick } = useBrandHero({ cutout, invert, id, url });

  return (
    <div className={container} id={id} data-invert={String(invert)}>
      <div className={imageContainer}>
        {(image?.desktop || image?.mobile) && (
          <img
            srcSet={`
                ${image?.mobile?.url} 480w,
                ${image?.desktop ? image?.desktop.url : image?.mobile?.url} 1200w
              `}
            sizes="(min-width: 777px) 100vw, 50vw"
            src={image?.mobile?.url || ""}
            alt={image?.desktop?.alt || ""}
            height={image?.desktop?.dimensions?.height}
            width={image?.desktop?.dimensions?.width}
          />
        )}
      </div>
      <div
        className={contentWrapper}
        data-theme={theme}
        data-invert={String(invert)}
        data-cutoutid={id}
      >
        <div className={content}>
          <h2>{title}</h2>
          <p>{description}</p>
          {ctaLabel && url && (
            <Button
              data-eventid={id}
              className={cta}
              onClick={() => handleClick(id, url)}
            >
              {ctaLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrandHeroComponent;
