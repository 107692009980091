import { pushEvent } from "@wbly/common";
import type { W, DataLayer } from "@wbly/common";

export const trackBrandHeroCtaClick = (id: string, ctaLink: string) => {
  pushEvent(
    (window as W)?.dataLayer as DataLayer[],
    "",
    `${id}`,
    `Redirected to ${ctaLink}`
  );
};
