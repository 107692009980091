import { useEffect } from "react";
import { trackBrandHeroCtaClick } from "./track-brand-hero-events";

type UseBrandHeroProps = {
  cutout: string;
  invert: boolean;
  id: string;
  url: string;
};

// Initializes the cutout element and handles resizing logic
const initializeCutout = (cutout: string, invert: boolean, id: string) => {
  const updateCutout = () => {
    const windowSize = window.innerWidth;
    const cutoutDesktopPosition = invert ? "right" : "left";
    const element = document.querySelector(`[data-cutoutid="${id}"]`);

    if (element) {
      const cutoutValue =
        windowSize < 1024
          ? `${cutout} top`
          : `${cutout} ${cutoutDesktopPosition}`;
      element.setAttribute("data-cutout", cutoutValue);
    }
  };

  // Initial update and event listener setup
  updateCutout();
  window.addEventListener("resize", updateCutout);

  // Cleanup function to remove event listener
  return () => {
    window.removeEventListener("resize", updateCutout);
  };
};

const handleClick = (id: string, url: string) => {
  // Handle CTA click with tracking
  window.location.href = url;
  trackBrandHeroCtaClick(id, url);
};

// Custom hook to manage brand hero cutout behaviour
const useBrandHero = ({ cutout, invert, id }: UseBrandHeroProps) => {
  useEffect(() => {
    const handleCutoutOrientation = initializeCutout(cutout, invert, id);
    return handleCutoutOrientation;
  }, [cutout, invert, id]);

  return {
    handleClick,
  };
};

export default useBrandHero;
